import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import swal from 'sweetalert';

class LoginForm extends Component {
    constructor(props){
        super(props);
    
        this.state = {
          fields: {},
          errors: {}
        }
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        //userame
        if(!fields["username"]){
          formIsValid = false;
          errors["username"] = "Username cannot be empty";
        }
    
        if(typeof fields["username"] !== "undefined"){
          if(!fields["username"].match(/^[a-zA-Z1-9]+$/)){
            formIsValid = false;
            errors["username"] = "Only letters and numbers";
          }      	
        }
    
        //password
        if(!fields["userpass"]){
          formIsValid = false;
          errors["userpass"] = "Password cannot be empty";
        }
        if(typeof fields["userpass"] !== "undefined"){
            if(fields["userpass"].length < 6){
              formIsValid = false;
              errors["userpass"] = "At least 6 characters";
            }      	
          }
    
        this.setState({errors: errors});
        return formIsValid;
    }

    async authenticateAPI(params) {
        const url ="/api/authenticate"
        const data = { 
                        username:params["username"], 
                        userpass:params["userpass"]
                    }

        return fetch(url, { method: "POST", 
            body: JSON.stringify(data),
            headers:{ "Content-Type": "application/json; charset=UTF-8" } 
        })
        .then(res => res.json())
        .catch(error => console.error("Error: ", error))
        .then(response => this.setUserData(response));
    }

    setUserData(response) {
        if(response.token === undefined) {
            swal({
                title: "Authentication failed",
                text: "Either your username or password does not match",
                icon: "error",
                timer: 3000,
                button: true
            })
        }
        else {
            localStorage.setItem('authToken', response.token);
            localStorage.setItem('currentUser', JSON.stringify(response.user));
            window.location = '/';
        }
        
    }
    
    async loginSubmit(e){
        e.preventDefault();
        if(this.handleValidation()){
            const data = { 
                username:this.state.fields["username"], 
                userpass:this.state.fields["userpass"]
            }
            //call API
            await this.authenticateAPI(data);
        }else{
            swal({
                title: "Error!",
                text: "Please enter required fields",
                icon: "warning",
                timer: 3000,
                button: true
            })
        }
    
    }
    
    handleChange(field, e){    		
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }


    render() {
        return (
            <Form onSubmit= {this.loginSubmit.bind(this)}>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <input type="text" className="form-control login-input" id="username" name="username" placeholder="User name" autocomplete="off" onChange={this.handleChange.bind(this, "username")}/>
                            <small className="error">{this.state.errors["username"]}</small>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <input type="password" className="form-control login-input" id="userpass" name="userpass" placeholder="Password" autocomplete="off" onChange={this.handleChange.bind(this, "userpass")}/>
                            <small className="error">{this.state.errors["userpass"]}</small>
                        </Form.Group>
                    </Col>
                </Row>

                <Row> 
                    <Col md={9}>
                        <span className="f12">Did you forget your Password?</span>
                    </Col>   
                    <Col md={3} className="text-right">
                        <Button type="submit" variant="custom">Verify</Button>
                    </Col>    
                </Row>
                
            </Form>
        )
    }
}

export default LoginForm;

import React from 'react'

export default function Footer() {
    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-xs-12 text-center">
                        <h5 className="h6 m-0">Diagnostic Center Administration</h5>
                        <h5 className="h6 m-0">Developed by <strong>Aranax Technologies Pvt Ltd</strong></h5>
                    </div>
                </div>
            </div>        
        </div>
    )
}

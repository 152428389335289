import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Navbar, Nav, NavDropdown  } from 'react-bootstrap';
import logo from "../../images/logo.png";
import avatar from "../../images/avatar.png";

export default function Topbar() {
    console.log("AUTH TOKEN", localStorage.getItem('authToken'));
    if (localStorage.getItem('authToken') === null) {
        return <Redirect to="/login" />;
    }

     var user = JSON.parse(localStorage.getItem('currentUser'))
    var t = user.token_expired_on.split(/[- :]/);
    var dt1 = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
    var dt2 = new Date();

    var diff =(dt1.getTime() - dt2.getTime()) / 1000;
    diff /= 60;
    var td = Math.round(diff);
    if(td > 720) {
        //console.log("Redirect to Login")
        localStorage.removeItem('authToken');
        localStorage.removeItem('currentUser');
        return <Redirect to="/login" />;
    }  

    return (
        <Navbar bg="dark" expand="lg" fixed="top">
            <Navbar.Brand href="/">
                <img src={logo} height="28" alt="Logo"/>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/">Dashboard</Nav.Link>
                    <NavDropdown title="Settings" id="settings-nav-dropdown">
                        <NavDropdown.Item href="/departments">Departments</NavDropdown.Item>
                        <NavDropdown.Item href="/doctors">Doctors</NavDropdown.Item>
                        <NavDropdown.Item href="/patients">Registered Patients</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/new-patient">New Patient</Nav.Link>
                    <Nav.Link href="/appointment">Appointment</Nav.Link>
                </Nav>

                <Nav>
                    <NavDropdown title={
                        <img className="" 
                            src={avatar} 
                            alt="avatar"
                        />
                    } id="profile-nav-dropdown" className="right">
                        <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                        <NavDropdown.Item href="/change-password">Change Password</NavDropdown.Item>
                        <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                
            </Navbar.Collapse>

        </Navbar>
    )
}

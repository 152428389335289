import React from 'react'
import { Route, Link, Switch, Redirect, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Topbar from  '../../components/common/Topbar'
import Footer from  '../../components/common/Footer'
import AppointmentForm from '../../components/appointment/AppointmentForm'

function AppointmentNewScreen() {
    let history = useHistory();
    const goToPreviousPath = () => {
        window.location = "/appointment";
    }

    return (
        <div className="wrapper">
            <Topbar />
            <Container fluid>
                <Row>
                    <Col md={12} xs={12}>
                        <div className="page-content mb-5">
                            <Row className="mb-3">
                                <Col md={6} xs={12}>
                                    <h1 className="h3">New Appointment</h1>
                                </Col>
                                <Col md={6} xs={12} className="text-right">
                                    <Link to="/appointment" className="btn btn-primary">Todays Appointments</Link>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={12} xs={12}>
                                    <AppointmentForm/>
                                </Col>    
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );                    

}

export default AppointmentNewScreen

import React, { Component } from 'react';

class Dashboard extends Component {
    constructor(props){
        super(props);
    
        this.state = {
          fields: {},
          errors: {}
        }
    }

    render() {
        

        return (
            <div>
                DASHBOARD PAGE CONTENT
            </div>
        )
    }
}

export default Dashboard;

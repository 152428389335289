import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';

function DoctorScheduleList(props) {
    const doctor = props.doctor;
    const token = localStorage.getItem('authToken');

    const [values, setValues] = useState({
        doc_id: '',
        weekday: '',
        start_time: '',
        end_time: '',
        per_pat_time: '',
        fees: '',
    });
    const [errors, setErrors] = useState({});
    const [schedule, setSchedule] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setSchedule(doctor.weekly_schedule);
    }, [doctor]);


    const openModal = () => {
        setIsModalOpen(true);
    };    
    const closeModal = () => setIsModalOpen(false);

    const validate = values => {
        let errors = {};

        if (!values.weekday) {
            errors.weekday = 'Weekday is required';
        }

        if (!values.start_time.trim()) {
            errors.start_time = 'Start time is required';
        }
        
        if (!values.end_time.trim()) {
            errors.end_time = 'End time is required';
        }

        if(new Date("Jan 01, 2021 " + values.start_time.trim()).getTime() >= new Date("Jan 01, 2021 " + values.end_time.trim()).getTime()){
            errors.end_time = 'End time should be greater';
        }

        if (!values.per_pat_time.trim()) {
            errors.per_pat_time = 'Per patient time is required';
        }
        else if(!/^[0-9]+$/.test(values.per_pat_time.trim())){
            errors.per_pat_time = 'Only number is accepted';
        }    

        if (!values.fees.trim()) {
            errors.fees = 'Fees is required';
        }else if(!/^[0-9]+$/.test(values.fees.trim())){
            errors.fees = 'Only number is accepted';
        } 

        return errors;
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
    
        setErrors(validate(values));
        setIsSubmitting(true);

        submitScheduleForm();
    };

    const submitScheduleForm = () => {
        //if (Object.keys(errors).length === 0 && isSubmitting) {
            console.log("SCHEDULE SUBMITTING");
            const data = { 
                doc_id:doctor.id,
                weekday:values.weekday,
                fees:values.fees, 
                start_time:values.start_time,    
                end_time:values.end_time,    
                per_pat_time:values.per_pat_time,
                is_active:1,  
            }
    
            const url = "/api/doctor/create-schedule";  
            return fetch(url, { method: "POST", 
                body: JSON.stringify(data),
                headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
            })
            .then(res => res.json())
            .then(response => getFormSubmitResponse(response))
            .catch(error => console.log(error));
        //}
    };

    const getFormSubmitResponse = (response) => {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              })
        }
        else {
            swal({
                title: "Success!",
                text: response.message,
                icon: "success",
                timer: 3000,
                button: true
              })

            setTimeout(function(){ window.location = '/doctors/' + doctor.doc_slug; }, 3000);
        }
        
    };

    return (
        <div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <h1 className="h4 m-0">Doctor's Schedule</h1>
                {
                    props.showNewBtn 
                        ? 
                            <Button type="button" onClick={openModal}>New</Button>
                        :
                            ''
                }

                    <Modal
                        show={isModalOpen} 
                        onHide={closeModal}
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                    >
                        <Modal.Header closeButton>
                        <Modal.Title>Create Schedule of {doctor.doc_name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <div className="p-3">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Col md={3} xs={12}>
                                            <select name="weekday" className="form-control" placeholder="Weekday" value={values.weekday} onChange={handleChange}>
                                                <option value="">Weekday</option>
                                                <option value="monday">Monday</option>
                                                <option value="tuesday">Tuesday</option>
                                                <option value="wednesday">Wednesday</option>
                                                <option value="thursday">Thursday</option>
                                                <option value="friday">Friday</option>
                                                <option value="saturday">Saturday</option>
                                                <option value="sunday">Sunday</option>
                                            </select>    
                                            {errors.weekday && <small className="error">{errors.weekday}</small>}
                                        </Col>
                                        <Col md={3} xs={12}>
                                            <input type="time" className="form-control" name="start_time" placeholder="Start Time" maxLength="10" autocomplete="off" 
                                            value={values.start_time} onChange={handleChange}/>
                                            {errors.start_time && <small className="error">{errors.start_time}</small>}
                                        </Col>
                                        <Col md={3} xs={12}>
                                            <input type="time" className="form-control" name="end_time" placeholder="End Time" maxLength="10" autocomplete="off" 
                                            value={values.end_time} onChange={handleChange}/>
                                            {errors.end_time && <small className="error">{errors.end_time}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6} xs={12}>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="per_pat_time" placeholder="Per patient time in minute" maxLength="2" autocomplete="off" value={values.per_pat_time} onChange={handleChange}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">minute</span>
                                                </div>
                                            </div>
                                            {errors.per_pat_time && <small className="error">{errors.per_pat_time}</small>}
                                        </Col>
                                        <Col md={3} xs={12}>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">&#8377;</span>
                                                </div>
                                                <input type="text" className="form-control" name="fees" placeholder="Fees in INR" maxLength="6" autocomplete="off" 
                                                value={values.fees} onChange={handleChange}/>
                                                
                                            </div>
                                            {errors.fees && <small className="error">{errors.fees}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6} xs={12}>
                                            <input type="hidden" name="doc_id"/>
                                            <Button type="submit" variant="primary">Save</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Modal.Body>
                    </Modal>
                
            </div>
            
            <div className="dcotor-profile-schedule">
            {
                
                schedule !== undefined && schedule.length > 0 ?
                    schedule.map((item, index) =>
                        <div className="schedule-item" key={index}>
                            <div className="font-weight-bold">{item.day}</div>
                            {item.schedule.map((i, n) =>
                                <div className="" key={n}>
                                    <div className="desc">
                                        <span>{i.sch_start_time} - {i.sch_end_time}</span>
                                    </div>
                                </div>    
                            )}
                        </div>    
                    )
                :
                    <h4 class="h4 text-muted">No data available</h4>
            }
            </div>
        </div>
    );

}

export default DoctorScheduleList
import moment from 'moment';

export function getCurrentMonth(){
    let month = moment().format('MMMM');
    return month;
}

export function getCurrentYear(){
    let year = moment().format('YYYY');
    return year;
}

export function getCurrentMonthYear(){
    let month_year = moment().format('MMMM-YYYY');
    return month_year;
}

export function getCurrentDate(dateFormat = 'DD-MMM-YYYY'){
    let today = moment().format(dateFormat);
    return today;
}

export function getNextDays(n = 7){
    let days = [];
    let daysRequired = n;

    for (let i = 0; i < daysRequired; i++) {
        days.push( moment().add(i, 'days').format('dddd,DD-MM-YYYY') )
    }
    return days;
}  

export function getExcerpt(text, len = 120){
    if(text == '') {
        return text;
    }
    else {
        if(text.length > len) {
            let excerpt = text.substr(0, len) + ' ...';
            return excerpt;
        }
        else {
            return text;
        }
    }
}

export function ucFirst(text){
    const textCapitalized = text.charAt(0).toUpperCase() + text.slice(1)
    return textCapitalized;
}

export function getBookingStatus(status) {
    if(status == 'BOOKED') {
        return "<span className='text-primary'>BOOKED</span>";
    }
    else if(status == 'CANCELLED') {
        return "<span className='text-danger'>CANCELLED</span>";
    }
    else if(status == 'CONFIRMED') {
        return "<span className='text-success'>CONFIRMED</span>";
    }
}

  

import React from "react";
import Webcam from "react-webcam";
import { Container, Row, Col, Button } from 'react-bootstrap';

const WebcamCapture = ({capturedImageCallback}) => {
    const webcamRef = React.useRef(null);
    const videoConstraints = {
        width: 180,
        height: 135,
        facingMode: "user"
      };
    const [imgSrc, setImgSrc] = React.useState(null);
  
    const capture = React.useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot({width: 180, height: 135});
      setImgSrc(imageSrc);
    }, [webcamRef, setImgSrc]);

    //this.props.capturedImageCallback();

    const uploadImage = React.useCallback(() => {
        //console.log("CAPTURE", imgSrc);
        capturedImageCallback(imgSrc);
        //event.preventDefault();
    });
  
    return (
      <Container>
          <Row>
              <Col md={4} xs={12}>
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg" 
                    screenshotQuality='0.95'
                    videoConstraints={videoConstraints}
                    />
              </Col>
              <Col md={4} xs={12} className="text-center">
                <Button onClick={capture} type="button" variant="primary" className="my-2">Capture Photo</Button>
                {imgSrc 
                    ?
                    <Button onClick={uploadImage} type="button" variant="primary" className="my-2">Upload Photo</Button>
                    : ''
                }    
              </Col>
              <Col md={4} xs={12}>
                {imgSrc && (
                    <img src={imgSrc} alt="preview"/>
                )}
              </Col>
          </Row>
        
        
        
      </Container>
    );
};

export default WebcamCapture;
import React, { Component } from 'react';
import { Route, Link, Switch, Redirect, withRouter } from 'react-router-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import DoctorForm from './DoctorForm'
import DoctorScheduleList from  './DoctorScheduleList'
import WebcamCapture from '../WebcamCapture'
import swal from 'sweetalert';

class DoctorView extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          doctor: '',
          department: '',
          schedule: [],
          isLoading: false,
          error: null,
          isEdit: false,
          isModalOpen: false,
          avatarData: null
        };
    }

    openModal = () => this.setState({ isModalOpen: true });
    closeModal = () => this.setState({ isModalOpen: false });

    handleCaptureImage = (imageData) =>{
        console.log("DOCTOR JS", imageData);
        this.setState({avatarData: imageData})

        const url = "/api/doctor/upload-avatar";
        const token = localStorage.getItem('authToken');
        const data = { 
            doc_id: this.state.doctor.id,
            doc_avatar: imageData, 
        }

        return fetch(url, { method: "POST", 
            body: JSON.stringify(data),
            headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
        })
        .then(res => res.json())
        .then(response => this.getFormSubmitResponse(response))
        .catch(error => this.setState({ error, isLoading: false }));

    }

    getFormSubmitResponse(response) {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              })
        }
        else {
            swal({
                title: "Success!",
                text: response.message,
                icon: "success",
                timer: 3000,
                button: true
              })

            setTimeout(function(){ window.location = '/doctors'; }, 3000);
        }
        
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const token = localStorage.getItem('authToken');
        fetch("/api/doctor/" + this.props.slug, {
            method: "GET",
            headers: {"token": token}})
            .then(response => response.json())
            .then(data => data.error === undefined ? this.setState({ doctor: data.doctor, 
                    department: data.doctor.department, 
                    schedule: data.doctor.weekly_schedule, 
                    error: data.error, isLoading: false, isEdit: false }) : 
                    this.setState({ error: data.error, isLoading: false }))
            .catch(error => this.setState({ error, isLoading: false, isEdit: false }));
    }

    toggleEdit(e){
        this.setState(state => ({      isEdit: !state.isEdit    }));
    }

    render() {
        
        const { doctor, department, schedule, isLoading, error, isEdit } = this.state;

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        if(error) {
            return (
                <div>
                    <Row>
                        <Col md={12} xs={12}>
                            <h4 class="h5">{error}</h4>
                        </Col>
                    </Row>
                </div>    
            )   
        }

        let doctorView;

        if(isEdit) {
            doctorView = <div className="profile-card">
                        <span className="action">
                            <Button type="button" className="btn-action" onClick={this.toggleEdit.bind(this)}>Close</Button>
                        </span>
                        <DoctorForm doctorInfo = {this.state.doctor} isEdit={true}/>
                    </div>;
        }
        else {
            doctorView = <div className="">
                <span className="action">
                    <Button type="button" className="btn-action" onClick={this.toggleEdit.bind(this)}>Edit</Button>
                </span>
                <h1 className="h4 font-weight-bold m-0 text-primary">{doctor.doc_name}</h1>
                <small>{doctor.doc_degree}</small>
                
                <p className="m-0">{doctor.doc_designation}</p>
                <p className="text-muted m-0">Department of {department.dept_name}</p>
                <p className="mt-2">
                    <i class="fas fa-phone round-icon"></i>{doctor.doc_phone}, {doctor.doc_phone_others}
                </p>
                <p className="mt-2">
                    <i class="fas fa-at round-icon"></i>{doctor.doc_email}
                </p>
                <p className="mt-2">
                    <i class="fas fa-map-marker-alt round-icon"></i>{doctor.doc_address}
                </p>

                <p className="my-3">
                    <h4 className="h6 font-weight-bold">About Doctor</h4>
                    <p className="text-muted">{doctor.doc_about}</p>
                </p>
            </div>
        }   

        return (
            <div>
                <Row>
                    <Col md={3} xs={12}>
                        <DoctorScheduleList doctor={doctor} showNewBtn={true}/>
                    </Col>
                    
                    <Col md={9} xs={12}>    
                        <div className="profile-card">
                            <Row>
                                <Col md={3} xs={12} className="text-center">
                                    <div className="avatar-container" onClick={this.openModal}>
                                        <img src={doctor.doc_photo}/>
                                        <div class="avatar-change-handle">
                                            <i class="fas fa-camera fa-2x"></i>
                                        </div>
                                    </div>
                                    <p className="mt-3 text-center">Reg. No. {doctor.doc_reg_num}</p>
                                    
                                    <Modal
                                        show={this.state.isModalOpen} 
                                        onHide={this.closeModal}
                                        backdrop="static"
                                        keyboard={false}
                                        size="lg"
                                    >
                                        <Modal.Header closeButton>
                                        <Modal.Title>Upload Photo</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="show-grid">
                                            <WebcamCapture capturedImageCallback={this.handleCaptureImage}/> 
                                        </Modal.Body>
                                    </Modal>

                                </Col>
                                <Col md={9} xs={12}>
                                    {doctorView}
                                </Col>
                            </Row>
                        </div>    
                    </Col> 
                </Row>                   
            </div>
                
        )
    }
}

export default DoctorView;

import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import swal from 'sweetalert';


class DepartmentForm extends Component {
    constructor(props){
        super(props);
    
        this.state = {
          fields: {},
          errors: {},
          isLoading: false,
          message: '',
          error: null,
        }

        if(this.props.isEdit) {
            let fields = this.state.fields;
            fields["dept_id"] = this.props.departmentInfo.id; 
            fields["dept_name"] = this.props.departmentInfo.dept_name;  
            fields["dept_description"] = this.props.departmentInfo.dept_description;    
            this.setState({fields});
        }
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        //dept_name
        if(!fields["dept_name"]){
          formIsValid = false;
          errors["dept_name"] = "Department name cannot be empty";
        }

        //dept_description
        if(!fields["dept_description"]){
            formIsValid = false;
            errors["dept_description"] = "Description cannot be empty";
        }
        if(typeof fields["dept_description"] !== "undefined"){
            if(fields["dept_description"].length > 500){    
              formIsValid = false;
              errors["dept_description"] = "Maximum 500 characters can be entered";
            }      	
        }
    
        this.setState({errors: errors});
        return formIsValid;
    }

    async saveDepartmentAPI(params) {
        const url = this.props.isEdit ? "/api/department/update" : "/api/department/create";
        const token = localStorage.getItem('authToken');
        const data = { 
            dept_id:params["dept_id"],
            dept_name:params["dept_name"], 
            dept_description:params["dept_description"],
        }

        return fetch(url, { method: "POST", 
            body: JSON.stringify(data),
            headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
        })
        .then(res => res.json())
        .then(response => this.getFormSubmitResponse(response))
        .catch(error => this.setState({ error, isLoading: false }));
    }

    getFormSubmitResponse(response) {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              })
        }
        else {
            swal({
                title: "Success!",
                text: response.message,
                icon: "success",
                timer: 3000,
                button: true
              })

            setTimeout(function(){ window.location = '/departments'; }, 3000);
        }
        
    }
    
    async departmentSubmit(e){
        e.preventDefault();
        if(this.handleValidation()){
            const data = { 
                dept_id:this.state.fields["dept_id"], 
                dept_name:this.state.fields["dept_name"], 
                dept_description:this.state.fields["dept_description"],
            }
            //call API
            await this.saveDepartmentAPI(data);
        }else{
            swal({
                title: "Error!",
                text: "Please enter required fields",
                icon: "error",
                timer: 3000,
                button: true
            })
        }
    
    }
    
    handleChange(field, e){    		
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }


    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        return (
            
            <Form onSubmit= {this.departmentSubmit.bind(this)}>
                <Row className="mb-3">
                    <Col md={9} xs={12}>
                        <input type="text" className="form-control" name="dept_name" placeholder="Department name" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "dept_name")} defaultValue={this.props.departmentInfo == null ? '' : this.props.departmentInfo.dept_name}/>
                        <small className="error">{this.state.errors["dept_name"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={9} xs={12}>
                        <textarea className="form-control" name="dept_description" placeholder="Department description" rows="6" maxLength="500" autocomplete="off" onChange={this.handleChange.bind(this, "dept_description")} defaultValue={this.props.departmentInfo == null ? '' : this.props.departmentInfo.dept_description}></textarea>
                        <small className="error">{this.state.errors["dept_description"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={6} xs={12}>
                        <input type="hidden" name="dept_id" defaultValue={this.props.departmentInfo == null ? '' : this.props.departmentInfo.id}/>
                        <Button type="submit" variant="primary" >Save</Button>
                    </Col>
                </Row>

            </Form>
                
            
        )
    }
}

export default DepartmentForm;

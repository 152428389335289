//import React from 'react'
import { Route, Link, Switch, Redirect, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Topbar from  '../../components/common/Topbar'
import Footer from  '../../components/common/Footer'
import DoctorForm from '../../components/doctor/DoctorForm'

function DoctorNewScreen() {
    let history = useHistory();
    const goToPreviousPath = () => {
        //history.goBack()
        window.location = "/doctors";
    }

    return (
        <div className="wrapper">
            <Topbar />
            <Container fluid>
                <Row>
                    <Col md={12} xs={12}>
                        <div className="page-content mb-5">
                            <Row className="mb-3">
                                <div className="col-md-6 col-xs-12">
                                    <h1 className="h3">Create New Doctor</h1>
                                </div>
                                <div className="col-md-6 col-xs-12 text-right">
                                    <Link to="/doctors" className="btn btn-primary">All Doctors</Link> 
                                </div>
                            </Row>

                            <Row className="mb-3">
                                <Col md={8} xs={12}>
                                    <div className="profile-card">
                                        <Row className="mb-3">
                                            <Col md={12} xs={12}>    
                                                <div className="">
                                                    <span className="action">
                                                        <Button type="button" className="btn-action" onClick={goToPreviousPath}>Close</Button>
                                                    </span>
                                                    
                                                    <DoctorForm doctorInfo = {null} isEdit={false}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        
                                    </div>
                                </Col>    
                            </Row>
                            
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
        
    )
}

export default DoctorNewScreen
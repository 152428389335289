//import React from 'react'
import { Route, Link, Switch, Redirect } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Topbar from  '../../components/common/Topbar'
import Footer from  '../../components/common/Footer'
import DepartmentList from '../../components/department/DepartmentList'

function DepartmentListScreen() {
    return (
        <div className="wrapper">
            <Topbar />
            <Container fluid>
                <Row>
                    <Col md={12} xs={12}>
                        <div className="page-content mb-5">
                            <Row className="mb-3">
                                <Col md={6} xs={12}>
                                    <h1 className="h3">Departments</h1>
                                </Col>
                                <Col md={6} xs={12} className="text-right">
                                    <Link to="/new-department" className="btn btn-primary">Add Department</Link>
                                </Col>
                            </Row>
                            <DepartmentList />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
        
    )
}

export default DepartmentListScreen

import React, { Component } from 'react'
import { Route, Link, Switch, Redirect } from 'react-router-dom';

export default class LogoutScreen extends Component {
    render() {
            
        localStorage.removeItem('authToken');
        localStorage.removeItem('currentUser');

        return <Redirect to="/" />;
    }
}

//import React from 'react'
import { Route, Link, Switch, Redirect } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Topbar from  '../components/common/Topbar'
import Footer from  '../components/common/Footer'
import Dashboard from '../components/Dashboard'

function DashboardScreen() {
    return (
        <div className="wrapper">
            <Topbar />
            <Container fluid>
                <Row>
                    <Col xs={12} md={12}>
                        <div className="page-content">
                            <h1 className="h3">Dashboard</h1>
                            <Dashboard />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
        
    )
}

export default DashboardScreen

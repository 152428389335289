import React, { useState, useEffect } from 'react'
import { Route, Link, Switch, Redirect, useHistory } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Topbar from  '../../components/common/Topbar'
import Footer from  '../../components/common/Footer'
import swal from 'sweetalert'
import {getCurrentDate} from '../../helpers/util'

function AppointmentScreen() {
    const goToPreviousPath = () => {
        window.location = "/departments";
    }

    const token = localStorage.getItem('authToken');
    const today = getCurrentDate('YYYY-MM-DD');
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [date, setDate] = useState('');
    const [doctors, setDoctors] = useState('');
    const [appointments, setAppointments] = useState({});
    let searchResponse = '';

    useEffect(() => {
        fetch(
            "/api/doctor",
          {
            method: "GET",
            headers: {"token": token}
          }
        )
          .then(response => response.json())
          .then(data => {
            setDoctors(data.doctors);
            getAppointments();
          })
          .catch(error => console.log(error));
      }, []);

    const validate = values => {
        let errors = {};

        if (!values.appointment_date && date == '') {
            errors.appointment_date = 'Date is required';
        }
        return errors;
    }  

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
    };  

    const handleSearch = e => {
        e.preventDefault();
        setErrors({});
        setIsSubmitting(true);
        //call API for search
        console.log("API CALLING.... ", );
        getAppointments();
    };

    const getAppointments = async () => {
        console.log("SUBMITTING.... ", isSubmitting);
        const appt_date = values.appointment_date === undefined ? today : values.appointment_date;
        const appt_doct = values.appointment_doctor === undefined ? 'ALL' : values.appointment_doctor;
        const appt_status = values.appointment_status === undefined ? 'ALL' : values.appointment_status;

        const url = "/api/appointment/" + appt_date + "/" + appt_doct + "/" + appt_status;  
        return await fetch(url, { method: "GET", 
            headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
        })
        .then(res => res.json())
        .then(response => getSearchResponse(response))
        .catch(error => console.log(error));  
        
    };

    const getSearchResponse = (response) => {
        console.log("RESPONSE ", response);
        if(response.error) {
            setErrors({error: response.error});
        }
        else {
            setAppointments(response.appointments);
        }

        console.log(appointments.length);
    };    

    const handleCancel = (num) => {
        console.log("CANCEL", num);
        swal({
            title: "Are you sure?",
            text: "The booking will be cancelled!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                console.log("DELETED", num);
                const data = { 
                    booking_id:num,
                }
        
                const url = "/api/appointment/cancel";  
                return fetch(url, { method: "POST", 
                    body: JSON.stringify(data),
                    headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
                })
                .then(res => res.json())
                .then(response => getBookingCancelResponse(response))
                .catch(error => console.log(error)); 
            } 
        });
    };

    const getBookingCancelResponse = (response) => {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              })
        }
        else {
            swal({
                title: "Success!",
                html:true,
                text: response.message,
                icon: "success",
                timer: 2000,
                button: true
              })

            setTimeout(function(){ window.location = '/appointment'; }, 3000);
        }
        
    };

    const handleRegister = (num, name, phone) => {
        console.log("REGISTER", num, name, phone);
        window.location = "/register-patient/" + encodeURIComponent(num) + "/" + encodeURIComponent(name) + "/" + encodeURIComponent(phone)
    }

    return (
        <div className="wrapper">
            <Topbar />
            <Container fluid>
                <Row>
                    <Col md={12} xs={12}>
                        <div className="page-content mb-5">
                            <Row className="mb-3">
                                <Col md={6} xs={12}>
                                    <h1 className="h3">Appointments</h1>
                                </Col>
                                <Col md={6} xs={12} className="text-right">
                                    <Link to="/new-appointment" className="btn btn-primary">New Appointment</Link>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={9} xs={12}>
                                    <div className="profile-card">
                                        <Row>
                                            <Col>
                                                <Form onSubmit={handleSearch}>
                                                    <Row>
                                                        <Col md={3}>
                                                            <input type="date" className="form-control" name="appointment_date" value={values.appointment_date} defaultValue={today} onChange={handleChange}/>  
                                                            {errors.appointment_date && <small className="error">{errors.appointment_date}</small>}
                                                        </Col>
                                                        <Col md={4}>
                                                            <select className="form-control" name="appointment_doctor" onChange={handleChange}>
                                                                <option value="ALL">All Doctors</option>
                                                                {
                                                                    doctors != undefined && doctors.length > 0  ? 
                                                                        doctors.map((item, index) => 
                                                                            <option value={item.id} key={index}>{item.doc_name}</option>
                                                                        )
                                                                    : null
                                                                }    
                                                            </select>
                                                        </Col>
                                                        <Col md={3}>
                                                            <select className="form-control" name="appointment_status" onChange={handleChange}>
                                                                <option value="ALL">All Status</option>
                                                                <option value="BOOKED">Booked</option>
                                                                <option value="CONFIRMED">Confirmed</option>
                                                                <option value="CANCELLED">Cancelled</option>
                                                            </select>    
                                                        </Col>
                                                        <Col md={2}>
                                                            <Button type="submit" variant="primary">Search</Button> 
                                                        </Col>
                                                    </Row>    
                                                </Form>
                                            </Col>
                                        </Row>    
                                    </div>
                                    
                                </Col>    
                            </Row>    
                            
                            <Row className="mb-3">
                                <Col md={12} xs={12}>
                                    {
                                        errors.error ?
                                            <Row>
                                                <Col md={12} xs={12}>
                                                    <p>{errors.error}</p>
                                                </Col>
                                            </Row>        
                                        : 
                                        appointments != undefined && appointments.length > 0  ? 
                                            appointments.map((item, index) => 
                                                <Row className="mb-1">
                                                    <Col md={9} xs={12}>
                                                        <div className="profile-card">
                                                            <Row className="m-0">
                                                                <Col md={3} xs={12}>
                                                                    <span className="font-weight-bold">{item.patient_name}</span>
                                                                    <br/>
                                                                    <span className="f14">{item.patient_phone}</span>
                                                                </Col>
                                                                <Col md={2} xs={6}>
                                                                    {item.slot_time}
                                                                    <br/>
                                                                    <span className="f14">{item.slot_date}</span>
                                                                </Col>
                                                                <Col md={3} xs={6}>
                                                                    <Link to={{pathname:`/doctors/${item.doc_slug}`}} className="text-primary" target="_blank">{item.doc_name}</Link><br/>
                                                                    <span className="f14"><Link to={{pathname:`/departments/${item.dept_slug}`}} className="text-primary" target="_blank">{item.dept_name}</Link></span>
                                                                </Col>
                                                                <Col md={2} xs={6} className="my-auto">
                                                                    {
                                                                        item.patient_type == 'OLD' 
                                                                        ?   <Link to={{pathname:`/patients/${item.patient_mrn}`}} className="text-primary" target="_blank">{item.patient_mrn}</Link> 
                                                                        :   <Badge variant="warning">New Patient</Badge>}
                                                                </Col>
                                                                <Col md={2} xs={6} className="my-auto text-center">
                                                                    {
                                                                        item.slot_date == getCurrentDate("DD-MM-YYYY") 
                                                                        ?
                                                                            item.patient_type == 'NEW' 
                                                                            ?   
                                                                                <div>
                                                                                    {
                                                                                        item.booking_status == 'BOOKED' 
                                                                                        ?
                                                                                        <div>
                                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Register Patient</Tooltip>}>
                                                                                            <span className="mr-3" onClick={() => handleRegister(item.booking_num, item.patient_name, item.patient_phone)}><i class="fas fa-user-plus action-btn"></i></span>
                                                                                        </OverlayTrigger>
                                                                                        
                                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Cancel Booking</Tooltip>}>
                                                                                            <span className="" onClick={() => handleCancel(item.booking_num)}><i class="fas fa-times action-btn danger"></i></span>
                                                                                        </OverlayTrigger>
                                                                                        </div>
                                                                                        :
                                                                                            <span className="text-secondary">{item.booking_status}</span>
                                                                                    }
                                                                                    
                                                                                </div>
                                                                            :   
                                                                                <div>
                                                                                    
                                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Pay &amp; Confirm</Tooltip>}>
                                                                                        <span className="mr-3"><i class="far fa-credit-card action-btn"></i></span>
                                                                                    </OverlayTrigger>

                                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Cancel Booking</Tooltip>}>
                                                                                        <span className="" onClick={() => handleCancel(item.booking_num)}><i class="fas fa-times action-btn danger"></i></span>
                                                                                    </OverlayTrigger>
                                                                                        
                                                                                </div>
                                                                        :
                                                                        
                                                                        ''
                                                                    }
                                                                </Col>
                                                            </Row>    
                                                        </div>    
                                                    </Col>
                                                </Row> 
                                            )
                                        : null    
                                    }
                                </Col>
                            </Row>   

                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );                    

}

export default AppointmentScreen

//import React from 'react'
import { Route, Link, Switch, Redirect, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Topbar from  '../../components/common/Topbar'
import Footer from  '../../components/common/Footer'
import Patient from '../../components/patient/Patient'

function PatientScreen() {
    let { mrn } = useParams();
    console.log("MRN", mrn);
    return (
        <div className="wrapper">
            <Topbar />
            <Container fluid>
                <Row>
                    <Col md={12} xs={12}>
                        <div className="page-content mb-5">
                            <Row className="mb-3">
                                <Col md={6} xs={12}>
                                    <h1 className="h3">More About Patient</h1>
                                </Col>
                                <Col md={6} xs={12} className="text-right">
                                    <Link to="/patients" className="btn btn-primary">All Patients</Link> 
                                </Col>
                            </Row>
                            <Patient mrn={mrn}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
        
    )
}

export default PatientScreen

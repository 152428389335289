import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import swal from 'sweetalert';


class DoctorForm extends Component {
    constructor(props){
        super(props);
    
        this.state = {
          fields: {},
          errors: {},
          isLoading: false,
          message: '',
          error: null,
          departments: [],
        }

        if(this.props.isEdit) {
            let fields = this.state.fields;
            fields["doc_id"] = this.props.doctorInfo.id; 
            fields["doc_name"] = this.props.doctorInfo.doc_name;  
            fields["doc_designation"] = this.props.doctorInfo.doc_designation;   
            fields["doc_department"] = this.props.doctorInfo.doc_department;    
            fields["doc_degree"] = this.props.doctorInfo.doc_degree;   
            fields["doc_reg_num"] = this.props.doctorInfo.doc_reg_num;   
            fields["doc_email"] = this.props.doctorInfo.doc_email;   
            fields["doc_phone"] = this.props.doctorInfo.doc_phone;   
            fields["doc_phone_others"] = this.props.doctorInfo.doc_phone_others;   
            fields["doc_gender"] = this.props.doctorInfo.doc_gender;    
            fields["doc_address"] = this.props.doctorInfo.doc_address;    
            fields["doc_about"] = this.props.doctorInfo.doc_about;   
            this.setState({fields});
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const token = localStorage.getItem('authToken');
        fetch("/api/department", {
            method: "GET",
            headers: {"token": token}})
          .then(response => response.json())
          .then(data => this.setState({ departments: data.departments, error: data.error, isLoading: false }))
          .catch(error => this.setState({ error, isLoading: false }));
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        //doc_name
        if(!fields["doc_name"]){
          formIsValid = false;
          errors["doc_name"] = "Doctor name cannot be empty";
        }
        if(typeof fields["doc_name"] !== "undefined"){
            if(!fields["doc_name"].match(/^[a-zA-Z .]+$/)){
              formIsValid = false;
              errors["doc_name"] = "Only alphabets accepted";
            }      	
        }

        //doc_designation
        if(!fields["doc_designation"]){
            formIsValid = false;
            errors["doc_designation"] = "Designation cannot be empty";
        }

        //doc_department
        if(!fields["doc_department"]){
            formIsValid = false;
            errors["doc_department"] = "Department cannot be empty";
        }

        //doc_degree
        if(!fields["doc_degree"]){
            formIsValid = false;
            errors["doc_degree"] = "Degree cannot be empty";
        }

        //doc_phone
        if(!fields["doc_phone"]){
            formIsValid = false;
            errors["doc_phone"] = "Mobile number cannot be empty";
        }
        if(typeof fields["doc_phone"] !== "undefined"){
            if(!fields["doc_phone"].match(/^\d{10}$/) || fields["doc_phone"].length < 10){    
            formIsValid = false;
            errors["doc_phone"] = "Enter 10 digits number";
            }      	
        }
  
        //doc_email
        if(!fields["doc_email"]){
            formIsValid = false;
            errors["doc_email"] = "Email address cannot be empty";
        }
        if(typeof fields["doc_email"] !== "undefined"){
            if(!fields["doc_email"].match(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)){    
              formIsValid = false;
              errors["doc_email"] = "Enter valid email address";
            }      	
        }

        //doc_gender
        if(!fields["doc_gender"]){
            formIsValid = false;
            errors["doc_gender"] = "Gender cannot be empty";
        }
    
        this.setState({errors: errors});
        return formIsValid;
    }

    async saveDoctorAPI(params) {
        const url = this.props.isEdit ? "/api/doctor/update" : "/api/doctor/create";
        const token = localStorage.getItem('authToken');
        const data = { 
            doc_id:params["doc_id"],
            doc_name:params["doc_name"], 
            doc_designation:params["doc_designation"],
            doc_department:params["doc_department"],    
            doc_degree:params["doc_degree"],   
            doc_reg_num:params["doc_reg_num"],   
            doc_email:params["doc_email"],   
            doc_phone:params["doc_phone"],   
            doc_phone_others:params["doc_phone_others"],   
            doc_gender:params["doc_gender"],    
            doc_address:params["doc_address"],    
            doc_about:params["doc_about"],   
        }

        return fetch(url, { method: "POST", 
            body: JSON.stringify(data),
            headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
        })
        .then(res => res.json())
        .then(response => this.getFormSubmitResponse(response))
        .catch(error => this.setState({ error, isLoading: false }));
    }

    getFormSubmitResponse(response) {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              })
        }
        else {
            swal({
                title: "Success!",
                text: response.message,
                icon: "success",
                timer: 3000,
                button: true
              })

            setTimeout(function(){ window.location = '/doctors'; }, 3000);
        }
        
    }
    
    async doctorSubmit(e){
        e.preventDefault();
        if(this.handleValidation()){
            const data = { 
                doc_id:this.state.fields["doc_id"], 
                doc_name:this.state.fields["doc_name"], 
                doc_designation:this.state.fields["doc_designation"],
                doc_department:this.state.fields["doc_department"],
                doc_degree:this.state.fields["doc_degree"],
                doc_reg_num:this.state.fields["doc_reg_num"],
                doc_email:this.state.fields["doc_email"],
                doc_phone:this.state.fields["doc_phone"],
                doc_phone_others:this.state.fields["doc_phone_others"],
                doc_gender:this.state.fields["doc_gender"],
                doc_address:this.state.fields["doc_address"],
                doc_about:this.state.fields["doc_about"],
            }
            //call API
            await this.saveDoctorAPI(data);
        }else{
            swal({
                title: "Error!",
                text: "Please enter required fields",
                icon: "error",
                timer: 3000,
                button: true
            })
        }
    
    }
    
    handleChange(field, e){    		
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }


    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        return (
            
            <Form onSubmit= {this.doctorSubmit.bind(this)}>
                <Row className="mb-3">
                    <Col md={6} xs={12}>
                        <input type="text" className="form-control" name="doc_name" placeholder="Doctor name" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "doc_name")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_name}/>
                        <small className="error">{this.state.errors["doc_name"]}</small>
                    </Col>
                    <Col md={4} xs={12}>
                        <input type="text" className="form-control" name="doc_designation" placeholder="Designation" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "doc_designation")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_designation}/>
                        <small className="error">{this.state.errors["doc_designation"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <select name="doc_department" className="form-control" placeholder="Department" onChange={this.handleChange.bind(this, "doc_department")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_department}>
                            <option value="">Department</option>
                        {this.state.departments.map(department => (
                            <option key={department.id} value={department.id}>
                                {department.dept_name}
                            </option>
                        ))}
                        </select>
                        <small className="error">{this.state.errors["doc_department"]}</small>
                    </Col>
                    <Col md={8} xs={12}>
                        <input type="text" className="form-control" name="doc_degree" placeholder="Degree" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "doc_degree")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_degree}/>
                        <small className="error">{this.state.errors["doc_degree"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <input type="text" className="form-control" name="doc_reg_num" placeholder="Registration Number" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "doc_reg_num")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_reg_num}/>
                        <small className="error">{this.state.errors["doc_reg_num"]}</small>
                    </Col>
                    <Col md={8} xs={12}>
                        <input type="text" className="form-control" name="doc_email" placeholder="Email Address" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "doc_email")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_email}/>
                        <small className="error">{this.state.errors["doc_email"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <input type="text" className="form-control" name="doc_phone" placeholder="Mobile Number" maxLength="10" autocomplete="off" onChange={this.handleChange.bind(this, "doc_phone")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_phone}/>
                        <small className="error">{this.state.errors["doc_phone"]}</small>
                    </Col>
                    <Col md={8} xs={12}>
                        <input type="text" className="form-control" name="doc_phone_others" placeholder="Alternate phone numbers" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "doc_phone_others")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_phone_others}/>
                        <small className="error">{this.state.errors["doc_phone_others"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <select name="doc_gender" className="form-control" placeholder="Gender" onChange={this.handleChange.bind(this, "doc_gender")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_gender}>
                            <option value="">Gender</option>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                        </select>
                        <small className="error">{this.state.errors["doc_gender"]}</small>
                    </Col>
                    <Col md={8} xs={12}>
                        <input type="text" className="form-control" name="doc_address" placeholder="Communication Address" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "doc_address")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_address}/>
                        <small className="error">{this.state.errors["doc_address"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12} xs={12}>
                        <textarea className="form-control" name="doc_about" placeholder="Brief info about doctor" rows="6" maxLength="500" autocomplete="off" onChange={this.handleChange.bind(this, "doc_about")} defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.doc_about}></textarea>
                        <small className="error">{this.state.errors["doc_about"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={6} xs={12}>
                        <input type="hidden" name="doc_id" defaultValue={this.props.doctorInfo == null ? '' : this.props.doctorInfo.id}/>
                        <Button type="submit" variant="primary" >Save</Button>
                    </Col>
                </Row>

            </Form>
                
            
        )
    }
}

export default DoctorForm;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import {getExcerpt} from '../../helpers/util'

class DoctorList extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          docs: [],
          isLoading: false,
          error: null,
        };
      }

      componentDidMount() {
        this.setState({ isLoading: true });
        const token = localStorage.getItem('authToken');
        fetch("/api/doctor", {
            method: "GET",
            headers: {"token": token}})
          .then(response => response.json())
          .then(data => this.setState({ docs: data.doctors, error: data.error, isLoading: false }))
          .catch(error => this.setState({ error, isLoading: false }));
      }

    render() {
        
        const { docs, isLoading, error } = this.state;

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        if(error) {
            return (
                <div>
                    <Row>
                        <Col md={12} xs={12}>
                            <h4 class="h5">{error}</h4>
                        </Col>
                    </Row>
                </div>    
            )   
        }

        return (
            <div>
                <Row>
                    {docs.map((doc) => (
                        <Col md={4} xs={12} key={doc.id}>
                            <div className="search-data-card shadow p-3">
                                <h3 className="h5 font-weight-bold m-0 d-flex justify-content-between">
                                    <span className="text-primary">
                                        {doc.doc_name}
                                    </span>
                                    <Link to={{pathname:`/doctors/${doc.doc_slug}`}}><i class="fas fa-user-md"></i></Link>
                                </h3>
                                <small>{doc.doc_degree == null ? '' : getExcerpt(doc.doc_degree, 50)}</small>
                                <p className="m-0">{doc.doc_designation}</p>
                                <p className="text-muted m-0">Department of {doc.department.dept_name}</p>
                            </div>    
                        </Col>
                    ))}    
                </Row>                
            </div>
                
        )
    }
}

export default DoctorList;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import {getExcerpt} from '../../helpers/util'


class DepartmentList extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          depts: [],
          isLoading: false,
          error: null,
        };
      }

      componentDidMount() {
        this.setState({ isLoading: true });
        const token = localStorage.getItem('authToken');
        fetch("/api/department", {
            method: "GET",
            headers: {"token": token}})
          .then(response => response.json())
          .then(data => this.setState({ depts: data.departments, error: data.error, isLoading: false }))
          .catch(error => this.setState({ error, isLoading: false }));
      }

    render() {
        
        const { depts, isLoading, error } = this.state;

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        if(error) {
            return (
                <div>
                    <Row>
                        <Col md={12} xs={12}>
                            <h4 class="h5">{error}</h4>
                        </Col>
                    </Row>
                </div>    
            )   
        }

        return (
            <div>
               
                <Row>
                    {depts.map((dept) => (
                        <Col md={4} xs={12} key={dept.id}>
                            <div className="search-data-card shadow p-3">
                                <h3 className="h5 font-weight-bold m-0 d-flex justify-content-between">
                                    <span className="text-primary">{dept.dept_name}</span>
                                    <span className="">
                                        <Link to={{pathname:`/departments/${dept.dept_slug}`}}><i className="fas fa-external-link-alt"></i></Link>
                                    </span>

                                    
                                </h3>
                                <div className="text-muted m-0 f14">{dept.dept_description == null ? '' : getExcerpt(dept.dept_description)}</div>
                            </div>    
                        </Col>
                    ))}    
                </Row>                
            </div>
                
        )
    }
}

export default DepartmentList;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Topbar from  '../components/common/Topbar';
import Footer from  '../components/common/Footer';
import Image404 from '../images/img_404.png';

const Page404 = ({ location }) => (
    <div className="wrapper">
        <Topbar />

        <div className="centerParentContaniner">
            <div className="parentContainer">
                <Container fluid>
                    <Row>
                        <Col xs={12} md={12}>
                            <div className="page-content">
                                <Row>
                                    <Col md={6} xs={12}>
                                        <img src={Image404} alt="404 error" className="p-5"/>
                                    </Col>

                                    <Col md={6} xs={12}>
                                        <div className="mt-5 py-5">
                                        <h1 className="h3">Awww...Don't Cry</h1>
                                        <p>It's just a 404 error!</p>
                                        <p className="my-5">No match found for <code>{location.pathname}</code></p>
                                    </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>    
        
        <Footer />
    </div>
);

export default Page404;
import React, { Component } from 'react';
//import { Route, Link, Switch, Redirect, withRouter } from 'react-router-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import PatientForm from './PatientForm'
import moment from 'moment';
import WebcamCapture from '../WebcamCapture'
import swal from 'sweetalert';

class Patient extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          patient: '',
          isLoading: false,
          error: null,
          isEdit: false,
          isModalOpen: false,
          avatarData: null
        };

    }

    openModal = () => this.setState({ isModalOpen: true });
    closeModal = () => this.setState({ isModalOpen: false });

    handleCaptureImage = (imageData) =>{
        console.log("PATIENT JS", imageData);
        this.setState({avatarData: imageData})

        const url = "/api/patient/upload-avatar";
        const token = localStorage.getItem('authToken');
        const data = { 
            patient_id: this.state.patient.id,
            patient_avatar: imageData, 
        }

        return fetch(url, { method: "POST", 
            body: JSON.stringify(data),
            headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
        })
        .then(res => res.json())
        .then(response => this.getFormSubmitResponse(response))
        .catch(error => this.setState({ error, isLoading: false }));

    }

    getFormSubmitResponse(response) {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              })
        }
        else {
            swal({
                title: "Success!",
                text: response.message,
                icon: "success",
                timer: 3000,
                button: true
              })

            setTimeout(function(){ window.location = '/patients'; }, 3000);
        }
        
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const token = localStorage.getItem('authToken');

        fetch("/api/patient/" + this.props.mrn, {
            method: "GET",
            headers: {"token": token}})
            .then(response => response.json())
            .then(data => this.setState({ patient: data.patient,  
                    error: data.error, isLoading: false, isEdit: false }))
            .catch(error => this.setState({ error, isLoading: false, isEdit: false }));
    }

    toggleEdit(e){
        this.setState(state => ({      isEdit: !state.isEdit    }));
    }

    render() {
        
        const { patient, isLoading, error, isEdit } = this.state;

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        if(error) {
            return (
                <div>
                    <Row>
                        <Col md={12} xs={12}>
                            <h4 class="h5">{error}</h4>
                        </Col>
                    </Row>
                </div>    
            )   
        }

        let scheduleList;
        let patientView;

        if(isEdit) {
            patientView = <div className="">
                        <span className="action">
                            <Button type="button" className="btn-action" onClick={this.toggleEdit.bind(this)}>Close</Button>
                        </span>
                        <PatientForm patientInfo = {this.state.patient} isEdit={true}/>
                    </div>;
        }
        else {
            patientView = <div className="">
                    <span className="action">
                        <Button type="button" className="btn-action" onClick={this.toggleEdit.bind(this)}>Edit</Button>
                    </span>
                    <h1 className="h4 font-weight-bold m-0 text-primary">{patient.patient_fullname}</h1>
                    <p className="m-0">{patient.patient_blood}, {patient.patient_gender}, {Math.round(moment().diff(patient.patient_dob, 'years', true))} Yr</p>

                    <p className="mt-2">
                        <i class="far fa-calendar-check round-icon"></i>
                        {moment(patient.patient_dob).format('DD-MMM-YYYY')}
                        
                    </p>
                    <p className="mt-2">
                        <i class="fas fa-phone round-icon"></i>{patient.patient_phone}
                    </p>
                    <p className="mt-2">
                        <i class="fas fa-at round-icon"></i>{patient.patient_email}
                    </p>
                    <p className="mt-2">
                        <i class="fas fa-map-marker-alt round-icon"></i>{patient.patient_address}
                    </p>
                </div>;
        }


        return (
            <div>
                <Row>
                    <Col md={3} xs={12}>
                        <h1 className="h4 mb-3">Upcoming Appointment</h1>
                        <div className="dcotor-profile-schedule">
                            {scheduleList}
                        </div>
                    </Col>
                    
                    <Col md={9} xs={12}>    
                        <div className="profile-card">
                            <Row>
                                <Col md={3} xs={12} className="text-center">
                                    <div className="avatar-container" onClick={this.openModal}>
                                        <img src={patient.patient_photo}/>
                                        <div class="avatar-change-handle">
                                            <i class="fas fa-camera fa-2x"></i>
                                        </div>
                                    </div>
                                    <p className="mt-3 text-center">{patient.patient_mrn}</p>
                                    
                                    <Modal
                                        show={this.state.isModalOpen} 
                                        onHide={this.closeModal}
                                        backdrop="static"
                                        keyboard={false}
                                        size="lg"
                                    >
                                        <Modal.Header closeButton>
                                        <Modal.Title>Upload Photo</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="show-grid">
                                            <WebcamCapture capturedImageCallback={this.handleCaptureImage}/> 
                                        </Modal.Body>
                                    </Modal>

                                </Col>
                                <Col md={9} xs={12}>
                                    {patientView}
                                </Col>
                            </Row>
                            
                        </div>
                    </Col> 
                </Row>                   
            </div>
                
        )
    }
}

export default Patient;

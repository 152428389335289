import React, { Component } from 'react';
//import { Route, Link, Switch, Redirect, useHistory } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import swal from 'sweetalert';


class PatientForm extends Component {
    constructor(props){
        super(props);
    
        this.state = {
          fields: {},
          errors: {},
          isLoading: false,
          message: '',
          error: null,
        }

        if(this.props.isEdit) {
            let fields = this.state.fields;
            fields["patient_id"] = this.props.patientInfo.id; 
            fields["patient_fullname"] = this.props.patientInfo.patient_fullname;  
            fields["patient_phone"] = this.props.patientInfo.patient_phone;    
            fields["patient_email"] = this.props.patientInfo.patient_email;
            fields["patient_dob"] = this.props.patientInfo.patient_dob;
            fields["patient_gender"] = this.props.patientInfo.patient_gender;    
            fields["patient_blood"] = this.props.patientInfo.patient_blood;    
            fields["patient_address"] = this.props.patientInfo.patient_address;    
            this.setState({fields});
        }
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        //patient_fullname
        if(!fields["patient_fullname"]){
          formIsValid = false;
          errors["patient_fullname"] = "Fullname cannot be empty";
        }
        if(typeof fields["patient_fullname"] !== "undefined"){
          if(!fields["patient_fullname"].match(/^[a-zA-Z ]+$/)){
            formIsValid = false;
            errors["patient_fullname"] = "Only alphabets accepted";
          }      	
        }
    
        //patient_phone
        if(!fields["patient_phone"]){
          formIsValid = false;
          errors["patient_phone"] = "Mobile number cannot be empty";
        }
        if(typeof fields["patient_phone"] !== "undefined"){
            if(!fields["patient_phone"].match(/^\d{10}$/) || fields["patient_phone"].length < 10){    
              formIsValid = false;
              errors["patient_phone"] = "Enter 10 digits number";
            }      	
        }

        //patient_email
        if(!fields["patient_email"]){
            formIsValid = false;
            errors["patient_email"] = "Email address cannot be empty";
        }
        if(typeof fields["patient_email"] !== "undefined"){
            if(!fields["patient_email"].match(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)){    
            formIsValid = false;
            errors["patient_email"] = "Enter valid email address";
            }      	
        }

        //patient_dob
        if(!fields["patient_dob"]){
            formIsValid = false;
            errors["patient_dob"] = "Date of birth cannot be empty";
        }

        //patient_gender
        if(!fields["patient_gender"]){
            formIsValid = false;
            errors["patient_gender"] = "Gender cannot be empty";
        }

        //patient_blood group
        if(!fields["patient_blood"]){
            formIsValid = false;
            errors["patient_blood"] = "Blood group cannot be empty";
        }
    
        //address
        if(!fields["patient_address"]){
            formIsValid = false;
            errors["patient_address"] = "Address cannot be empty";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    async savePatientAPI(params) {
        const url = this.props.isEdit ? "/api/patient/update" : "/api/patient/create";
        const token = localStorage.getItem('authToken');
        const data = { 
                        patient_id:params["patient_id"],
                        patient_fullname:params["patient_fullname"], 
                        patient_phone:params["patient_phone"],
                        patient_email:params["patient_email"],
                        patient_dob:params["patient_dob"],
                        patient_gender:params["patient_gender"],
                        patient_blood:params["patient_blood"],
                        patient_address:params["patient_address"],
                    }

        return fetch(url, { method: "POST", 
            body: JSON.stringify(data),
            headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
        })
        .then(res => res.json())
        .then(response => this.getFormSubmitResponse(response))
        .catch(error => this.setState({ error, isLoading: false }));
    }

    getFormSubmitResponse(response) {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              })
        }
        else {
            swal({
                title: "Success!",
                text: response.message,
                icon: "success",
                timer: 3000,
                button: true
              })

            setTimeout(function(){ window.location = '/patients'; }, 3000);
        }
        
    }
    
    async patientSubmit(e){
        e.preventDefault();
        if(this.handleValidation()){
            const data = { 
                patient_id:this.state.fields["patient_id"], 
                patient_fullname:this.state.fields["patient_fullname"], 
                patient_phone:this.state.fields["patient_phone"],
                patient_email:this.state.fields["patient_email"],
                patient_dob:this.state.fields["patient_dob"],
                patient_gender:this.state.fields["patient_gender"],
                patient_blood:this.state.fields["patient_blood"],
                patient_address:this.state.fields["patient_address"],
            }
            //call API
            await this.savePatientAPI(data);
        }else{
            swal({
                title: "Error!",
                text: "Please enter required fields",
                icon: "error",
                timer: 3000,
                button: true
            })
        }
    
    }
    
    handleChange(field, e){    		
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }


    render() {
        const { isLoading, message, error } = this.state;

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        return (
            
            <Form onSubmit= {this.patientSubmit.bind(this)}>
                <Row className="mb-3">
                    <Col md={6} xs={12}>
                        <input type="text" className="form-control" name="patient_fullname" placeholder="Fullname" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "patient_fullname")} defaultValue={this.props.patientInfo == null ? '' : this.props.patientInfo.patient_fullname}/>
                        <small className="error">{this.state.errors["patient_fullname"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <input type="patient_phone" className="form-control" name="patient_phone" placeholder="Mobile Number" maxLength="10" autocomplete="off" onChange={this.handleChange.bind(this, "patient_phone")} defaultValue={this.props.patientInfo == null ? '' : this.props.patientInfo.patient_phone}/>
                        <small className="error">{this.state.errors["patient_phone"]}</small>
                    </Col>
                    <Col>
                        <input type="text" className="form-control" name="patient_email" placeholder="Email Address" maxLength="100" autocomplete="off" onChange={this.handleChange.bind(this, "patient_email")} defaultValue={this.props.patientInfo == null ? '' : this.props.patientInfo.patient_email}/>
                        <small className="error">{this.state.errors["patient_email"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <input type="date" className="form-control" name="patient_dob" placeholder="Date of Birth" onChange={this.handleChange.bind(this, "patient_dob")}  defaultValue={this.props.patientInfo == null ? '' : this.props.patientInfo.patient_dob}/>
                        <small className="error">{this.state.errors["patient_dob"]}</small>
                    </Col>
                    <Col>
                        <select name="patient_gender" className="form-control" placeholder="Gender" onChange={this.handleChange.bind(this, "patient_gender")} defaultValue={this.props.patientInfo == null ? '' : this.props.patientInfo.patient_gender}>
                            <option value="">Gender</option>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                        </select>
                        <small className="error">{this.state.errors["patient_gender"]}</small>
                    </Col>
                    <Col>
                        <select name="patient_blood" className="form-control" placeholder="patient_blood Group"  onChange={this.handleChange.bind(this, "patient_blood")} defaultValue={this.props.patientInfo == null ? '' : this.props.patientInfo.patient_blood}>
                            <option value="">Blood Group</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                        </select>
                        <small className="error">{this.state.errors["patient_blood"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <input type="text" className="form-control" name="patient_address" placeholder="Address"  maxLength="200" onChange={this.handleChange.bind(this, "patient_address")} defaultValue={this.props.patientInfo == null ? '' : this.props.patientInfo.patient_address}/>
                        <small className="error">{this.state.errors["patient_address"]}</small>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={6} xs={12}>
                        <input type="hidden" name="patient_id" defaultValue={this.props.patientInfo == null ? '' : this.props.patientInfo.id}/>
                        <Button type="submit" variant="primary" >Save</Button>
                    </Col>
                </Row>

            </Form>
        )
    }
}

export default PatientForm;

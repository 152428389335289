import React, { useState } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import {ucFirst} from '../../helpers/util'
import {getNextDays} from '../../helpers/util'

function AppointmentForm() {
    const token = localStorage.getItem('authToken');

    const goToPreviousPath = () => {
        window.location = "/appointment";
    }

    const [values, setValues] = useState({
        doc_id: '',
        weekday: '',
        start_time: '',
        end_time: '',
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [availableDoctors, setAvailableDoctors] = useState({});
    const [weekdates, setWeekdates] = useState(getNextDays(7));
    const [weekday, setWeekday] = useState('');
    const [weekdate, setWeekdate] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [doctorId, setDoctorId] = useState('');
    const [slots, setSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const validate = values => {
        let errors = {};

        if (!values.patient_name) {
            errors.patient_name = 'Patient name is required';
        }

        if (!values.patient_phone) {
            errors.patient_phone = 'Mobile number is required';
        }

        return errors;
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });

        getAvailableSlots(value);
    };

    const getAvailableSlots = (docid) => {
        console.log("DOC ID", docid, weekday, weekdate);
        if(docid > 0) {
            setDoctorId(docid);
            fetch(
                "/api/doctor/slots/" + weekdate + "/" + weekday + "/" + docid,
            {
                method: "GET",
                headers: {"token": token}
            }
            )
            .then(response => response.json())
            .then(response => getDoctorScheduleResponse(response))
            .catch(error => console.log(error));
        }
        
    };
    
    const getDoctorScheduleResponse = (response) => {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              });
              setSlots([]); 
        }
        else {
            console.log(response.slots);
            setSlots(response.slots);
        }
        
    };

    const loadDoctors = (w) => {
        console.log("loadDoctors" , w);
        //get doctors on weekday
        let wdate = w.split(",");
        let weekday = wdate[0].toLowerCase();
        let weekdate = wdate[1];
        
        setSelectedDate(w);
        setWeekday(weekday);
        setWeekdate(weekdate)
        setDoctorId('');
        setSlots([]);

        fetch(
            "/api/doctor/available/" + weekdate + "/" + weekday,
          {
            method: "GET",
            headers: {"token": token}
          }
        )
        .then(response => response.json())
        .then(response => getDoctorAvailabilityResponse(response))
        .catch(error => console.log(error));
    };

    const getDoctorAvailabilityResponse = (response) => {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              });
            setAvailableDoctors({});  
        }
        else {
            setAvailableDoctors(response.doctors);
        }
        
    };

    const listDates = weekdates.map((w) =>  <div className="mr-3">
        <Button type="button" variant="outline-primary" onClick={() => loadDoctors(w)}>{
            w.toString().replace(",", "\n")
        }</Button></div>);

    const openModal = (slot) => {
        //console.log("SLOT SELECTED", slot, selectedDate, doctorId);
        setSelectedSlot(slot);
        setIsModalOpen(true);
    };    
    const closeModal = () => setIsModalOpen(false);

    const handleModalChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
    };
    
    const submitAppointment = e => {
        e.preventDefault();
    
        setErrors(validate(values));
        setIsSubmitting(true);

        submitAppointmentForm();
    };

    const submitAppointmentForm = () => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            console.log("APPOINTMENT SUBMITTING");
            const data = { 
                slot_doctor:doctorId,
                slot_time:selectedSlot,
                slot_date:selectedDate, 
                patient_name:values.patient_name,    
                patient_phone:values.patient_phone,    
            }
    
            const url = "/api/appointment/create";  
            return fetch(url, { method: "POST", 
                body: JSON.stringify(data),
                headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
            })
            .then(res => res.json())
            .then(response => getFormSubmitResponse(response))
            .catch(error => console.log(error));  
        }
    };

    const getFormSubmitResponse = (response) => {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              })
        }
        else {
            swal({
                title: "Success!",
                html:true,
                text: response.message,
                icon: "success",
                timer: 3000,
                button: true
              })

            setTimeout(function(){ window.location = '/appointment'; }, 3000);
        }
        
    };

    return (
        <div>
            <div className="profile-card">
                <Row>
                    <Col md={12}>
                        <span className="action">
                            <Button type="button" className="btn-action" onClick={goToPreviousPath}>Close</Button>
                        </span>
                        <div className="p-2 m-3">
                            <Form>
                                <Row className="mb-3">
                                    <Col md={12} xs={12}>
                                        <div className="weekdates">
                                            {listDates}
                                        </div>
                                    </Col>
                                </Row>   

                                <Row className="mb-3">
                                    <Col md={6} xs={12}>
                                        {
                                            availableDoctors !== undefined && availableDoctors.length > 0  ? 
                                            <>
                                                <select name="doc_id" className="form-control col-md-9" placeholder="Doctor" value={doctorId} onChange={handleChange}>
                                                    <option value="">Doctors available on {ucFirst(weekday)}</option>
                                                    {
                                                        availableDoctors.map((item, index) => 
                                                            <option value={item.doc_id} key={index}>{item.doc_name}</option>
                                                        )
                                                    }    
                                                </select>
                                                <small className="error"></small>
                                            </>
                                            : ''
                                        }
                                    </Col>
                                </Row> 

                                <Row className="my-3">
                                    <Col md={12} xs={12}>
                                        {
                                            slots !== undefined && slots.length > 0  
                                            ?
                                            <>
                                                <h4 class="h5 font-weight-bold">Available slots on <span className="text-primary">{selectedDate}</span></h4>
                                                <div className="appointment-slots d-flex flex-wrap mt-3"> 
                                                    {slots.map((item, index) => 
                                                        <div className="slot" key={index} onClick={() => openModal(item)}>{item}</div>
                                                    )}
                                                </div> 
                                            </>   
                                            : 
                                                ""
                                        }   
                                    </Col>
                                </Row>
                                
                            </Form>    
                                
                            <Modal
                                show={isModalOpen} 
                                onHide={closeModal}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                <Modal.Title>Set Appointment on {selectedSlot}, {selectedDate}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="show-grid">
                                    <Form onSubmit={submitAppointment}>
                                        <Row className="mb-3">
                                            <Col md={9} xs={12}>
                                                <input type="text" className="form-control" name="patient_name" placeholder="Patient Fullname" maxLength="100" autocomplete="off" 
                                                value={values.patient_name} onChange={handleModalChange}/>
                                                {errors.patient_name && <small className="error">{errors.patient_name}</small>}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">    
                                            <Col md={6} xs={12}>
                                                <input type="text" className="form-control" name="patient_phone" placeholder="Mobile Number" maxLength="10" autocomplete="off" value={values.patient_phone} onChange={handleModalChange}/>
                                                {errors.patient_phone && <small className="error">{errors.patient_phone}</small>}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={6} xs={12}>
                                                <input type="hidden" name="slot_doctor" value={doctorId}/>
                                                <input type="hidden" name="slot_time" value={selectedSlot}/>
                                                <input type="hidden" name="slot_date" value={selectedDate}/>
                                                <Button type="submit" variant="primary" >Save</Button>
                                            </Col>
                                        </Row>    
                                    </Form>    
                                </Modal.Body>
                            </Modal>
                            
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );    
}
export default AppointmentForm    
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import LoginScreen from  "./screens/LoginScreen"
import LogoutScreen from  "./screens/LogoutScreen"
import DashboardScreen from  "./screens/DashboardScreen"

import DepartmentListScreen from  "./screens/department/DepartmentListScreen"
import DepartmentViewScreen from  "./screens/department/DepartmentViewScreen"
import DepartmentNewScreen from  "./screens/department/DepartmentNewScreen"

import DoctorListScreen from  "./screens/doctor/DoctorListScreen"
import DoctorViewScreen from  "./screens/doctor/DoctorViewScreen"
import DoctorNewScreen from  "./screens/doctor/DoctorNewScreen"

import PatientListScreen from  "./screens/patient/PatientListScreen"
import PatientScreen from  "./screens/patient/PatientScreen"
import PatientNewScreen from  "./screens/patient/PatientNewScreen"
import PatientRegisterScreen from  "./screens/patient/PatientRegisterScreen"

import AppointmentScreen from  "./screens/appointment/AppointmentScreen"
import AppointmentNewScreen from  "./screens/appointment/AppointmentNewScreen"

import Page404 from './screens/404'

import './App.css';

class App extends Component {
  /* constructor(props) {
    super(props);
  } */

  render() {
    const renderScreen = () => {
        return (
          <Router>
              <Switch>
                  <Route exact path="/" component={DashboardScreen} />
                  <Route exact path="/login" component={LoginScreen} />
                  <Route exact path="/logout" component={LogoutScreen} />

                  <Route exact path="/departments" component={DepartmentListScreen} />
                  <Route exact path="/departments/:slug" component={DepartmentViewScreen} />
                  <Route exact path="/departments/:slug/doctors" component={DepartmentViewScreen} />
                  <Route exact path="/new-department" component={DepartmentNewScreen} />

                  <Route exact path="/doctors" component={DoctorListScreen} />
                  <Route exact path="/doctors/:slug" component={DoctorViewScreen} />
                  <Route exact path="/new-doctor" component={DoctorNewScreen} />

                  <Route exact path="/patients" component={PatientListScreen} />
                  <Route exact path="/patients/:mrn" component={PatientScreen} />
                  <Route exact path="/new-patient" component={PatientNewScreen} />
                  <Route exact path="/register-patient/:bookingid/:name/:phone" component={PatientRegisterScreen} />
                  
                  <Route exact path="/appointment" component={AppointmentScreen} />
                  <Route exact path="/new-appointment" component={AppointmentNewScreen} />

                  <Route component={Page404} />
              </Switch>
          </Router>
        );
    }

    return (
      <div className="App">
        {renderScreen()}
      </div>
    );
    
  }

}

export default App;
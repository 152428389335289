//import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import LoginForm from '../components/LoginForm'
import logo from "../images/logo_dark.png";

function LoginScreen() {
    return (
        <div className="login-screen">
            <div className="centerParentContaniner">
                <div className="parentContainer">
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col xs={12} md={12}>
                                <div className="box login p-3">
                                    <div className="login-brand mb-5">
                                        <img src={logo} />
                                        <h1 className="f20 m-0 pt-2 pl-3">
                                            Welcome to<br/>Diagnostic Center Administration
                                        </h1>
                                    </div>
                                    <LoginForm />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            
        </div>
    )
}

export default LoginScreen

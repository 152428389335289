import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import swal from 'sweetalert';

function PatientRegisterForm(props) {
    const patient = props.patient;
    const token = localStorage.getItem('authToken');
    const [values, setValues] = useState({
        patient_id: '',
        patient_fullname: patient.name,
        patient_phone: patient.phone,
        patient_email: '',
        patient_dob: '',
        patient_gender: '',
        patient_blood: '',
        patient_address: '',
        payment_amount: '',
        payment_mode: '',
        payment_card_num: ''
    });
    const [paymentMode, setPaymentMode] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });

        if(name === 'payment_mode' &&  value !== undefined) {
            setPaymentMode(value);
        }
        
    };

    const validate = values => {
        let errors = {};

        //patient_fullname
        if (!values.patient_fullname.trim()) {
            errors.patient_fullname = 'Fullname cannot be empty';
        }
        else if(!values.patient_fullname.trim().match(/^[a-zA-Z ]+$/)){
            errors.patient_fullname = "Only alphabets accepted";
        }   
        
        //patient_phone
        if(!values.patient_phone.trim()){
            errors.patient_phone = "Mobile number cannot be empty";
        }
        else if(!values.patient_phone.trim().match(/^\d{10}$/) || 
                values.patient_phone.trim().length < 10){    
            errors.patient_phone = "Enter 10 digits number";
        }    
        
        //patient_email
        if(!values.patient_email.trim()){
            errors.patient_email = "Email address cannot be empty";
        }
        else if(!values.patient_email.trim().match(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)){    
            errors.patient_email = "Enter valid email address";
        }

        //patient_dob
        if(!values.patient_dob){
            errors.patient_dob = "Date of birth cannot be empty";
        }

        //patient_gender
        if(!values.patient_gender){
            errors.patient_gender = "Gender cannot be empty";
        }

        //patient_blood group
        if(!values.patient_blood){
            errors.patient_blood = "Blood group cannot be empty";
        }
    
        //address
        if(!values.patient_address){
            errors.patient_address = "Address cannot be empty";
        }

        //payment amount
        if(!values.payment_amount){
            errors.payment_amount = "Amount cannot be empty";
        }

        //payment mode
        if(!values.payment_mode){
            errors.payment_mode = "Payment mode cannot be empty";
        }

        //payment card num
        if(paymentMode == 'CARD') {
            if(!values.payment_card_num){
                errors.payment_card_num = "Card number cannot be empty";
            }
        }

        return errors;
    }

    const patientSubmit = e => {
        e.preventDefault();
    
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    useEffect(
        () => {
            if (Object.keys(errors).length === 0 && isSubmitting) {
                console.log("SUBMITTING");
                const data = { 
                    patient_id:values.patient_id,
                    patient_fullname:values.patient_fullname,
                    patient_phone:values.patient_phone,
                    patient_email:values.patient_email,
                    patient_dob:values.patient_dob,
                    patient_gender:values.patient_gender,
                    patient_blood:values.patient_blood,
                    patient_address:values.patient_address,
                    booking_id:patient.bookingid,
                    payment_amount:values.payment_amount,
                    payment_mode:values.payment_mode,
                    payment_card_num:values.payment_card_num
                }
        
                const url = "/api/patient/register-appointment";  
                return fetch(url, { method: "POST", 
                    body: JSON.stringify(data),
                    headers:{ "Content-Type": "application/json; charset=UTF-8", "token": token } 
                })
                .then(res => res.json())
                .then(response => getFormSubmitResponse(response))
                .catch(error => console.log(error));  
            }
        }
    );

    const getFormSubmitResponse = (response) => {
        if(response.error) {
            swal({
                title: "Error!",
                text: response.error,
                icon: "error",
                timer: 3000,
                button: true
              })
        }
        else {
            swal({
                title: "Success!",
                text: response.message,
                icon: "success",
                timer: 2000,
                button: true
              })

            setTimeout(function(){ window.location = '/appointment'; }, 3000);
        }
    };

    return (
        <div>
            <Form onSubmit= {patientSubmit}>
                <Row className="mb-3">
                    <Col md={6} xs={12}>
                        <input type="text" className="form-control" name="patient_fullname" placeholder="Fullname" maxLength="100" autocomplete="off" value={values.patient_fullname} onChange={handleChange} defaultValue={patient == null ? '' : patient.name}/>
                        {errors.patient_fullname && <small className="error">{errors.patient_fullname}</small>}
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <input type="patient_phone" className="form-control" name="patient_phone" placeholder="Mobile Number" maxLength="10" autocomplete="off" value={values.patient_phone} onChange={handleChange} defaultValue={patient == null ? '' : patient.phone}/>
                        {errors.patient_phone && <small className="error">{errors.patient_phone}</small>}
                    </Col>
                    <Col>
                        <input type="text" className="form-control" name="patient_email" placeholder="Email Address" maxLength="100" autocomplete="off" value={values.patient_email} onChange={handleChange}/>
                        {errors.patient_email && <small className="error">{errors.patient_email}</small>}
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <input type="date" className="form-control" name="patient_dob" placeholder="Date of Birth" value={values.patient_dob} onChange={handleChange}/>
                        {errors.patient_dob && <small className="error">{errors.patient_dob}</small>}
                    </Col>
                    <Col>
                        <select name="patient_gender" className="form-control" placeholder="Gender" value={values.patient_gender} onChange={handleChange}>
                            <option value="">Gender</option>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                        </select>
                        {errors.patient_gender && <small className="error">{errors.patient_gender}</small>}
                    </Col>
                    <Col>
                        <select name="patient_blood" className="form-control" placeholder="patient_blood Group" value={values.patient_blood} onChange={handleChange}>
                            <option value="">Blood Group</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                        </select>
                        {errors.patient_blood && <small className="error">{errors.patient_blood}</small>}
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <input type="text" className="form-control" name="patient_address" placeholder="Address"  maxLength="200" value={values.patient_address} onChange={handleChange}/>
                        {errors.patient_address && <small className="error">{errors.patient_address}</small>}
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <input type="text" className="form-control" name="payment_amount" placeholder="Registration fees"  maxLength="80" value={values.payment_amount} onChange={handleChange}/>
                        {errors.payment_amount && <small className="error">{errors.payment_amount}</small>}
                    </Col>
                    <Col>
                        <select name="payment_mode" className="form-control" placeholder="Payment mode" value={values.payment_mode} onChange={handleChange}>
                            <option value="">Payment Mode</option>
                            <option value="CASH">Cash</option>
                            <option value="CARD">Card</option>
                        </select>
                        {errors.payment_mode && <small className="error">{errors.payment_mode}</small>}
                    </Col>
                    <Col>
                    {
                        paymentMode == 'CARD' 
                        ?
                            <div>
                                <input type="text" className="form-control" name="payment_card_num" placeholder="Last 4 digits of Card"  maxLength="4" value={values.payment_card_num} onChange={handleChange} />
                                {errors.payment_card_num && <small className="error">{errors.payment_card_num}</small>}
                            </div>    
                        :
                        null
                    }
                        
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={6} xs={12}>
                        <input type="hidden" name="patient_id" value={values.patient_id}/>
                        <Button type="submit" variant="primary" >Save</Button>
                    </Col>
                </Row>

            </Form>
        </div>
    );    
}
export default PatientRegisterForm
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import DepartmentForm from './DepartmentForm'

class DepartmentView extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
            dept: '',
            docs: [],
            isLoading: false,
            error: null,
            isEdit: false,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const token = localStorage.getItem('authToken');
        fetch("/api/department/" + this.props.slug, {
            method: "GET",
            headers: {"token": token}})
          .then(response => response.json())
          .then(data => data.error === undefined ? 
                            this.setState({ dept: data.department, docs: data.department.department_doctors, error: data.error, isLoading: false }) : 
                            this.setState({ error: data.error, isLoading: false }))
          .catch(error => this.setState({ error, isLoading: false }));
    }

    toggleEdit(e){
        this.setState(state => ({      isEdit: !state.isEdit    }));
    }  

    render() {
        
        const { dept, docs, isLoading, error, isEdit  } = this.state;

        if (isLoading) {
            return <p>Loading ...</p>;
        }
        
        if(error) {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <h4 class="h5">{error}</h4>
                        </div>
                    </div>
                </div>    
            )   
        }
        
        let deptView;

        if(isEdit) {
            deptView = <div className="profile-card">
                        <span className="action">
                            <Button type="button" className="btn-action" onClick={this.toggleEdit.bind(this)}>Close</Button>
                        </span>
                        <DepartmentForm departmentInfo = {this.state.dept} isEdit={true}/>
                    </div>;
        }
        else {
            deptView = <div className="">
                <Row>
                    <Col md={12}>
                        <h2 className="h4 font-weight-bold m-0 d-flex justify-content-between">
                            <span className="text-primary">
                                Department of {dept.dept_name}
                            </span>
                            <span className="action">
                                <Button type="button" className="btn-action" onClick={this.toggleEdit.bind(this)}>Edit</Button>
                            </span>
                        </h2>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col md={12}>
                        <p>{dept.dept_description}</p>
                    </Col>
                </Row>
            </div>

        }    


        return (
            <div>
                
                {deptView}

                <Row className="mt-3">
                    <Col md={12}>
                        <h3 class="h4">Doctors</h3>
                        <p>The clinical team consists of renowned specialists with a significant amount of expertise about the afflictions of the human body, and the latest advances in medical science and technology.</p>
                    </Col>
                </Row>
                <Row>
                    {
                    docs.length === 0 
                    ?   
                        <Col md={12} xs={12}>
                            <h4 className="h5">No doctor information available</h4>
                        </Col>
                    : 
                    docs.map((doc) => (
                        <Col md={4} xs={12} key={doc.id}>
                            <div className="search-data-card shadow p-3">
                                <h3 className="h5 font-weight-bold m-0 d-flex justify-content-between">
                                    <span className="text-primary">
                                        {doc.doc_name}
                                    </span>
                                    <Link to={{pathname:`/doctors/${doc.doc_slug}`}}><i class="fas fa-user-md"></i></Link>
                                </h3>
                                <small>{doc.doc_degree}</small>
                                <p className="m-0">{doc.doc_designation}</p>
                            </div>    
                        </Col>
                    ))}    
                </Row>                   
            </div>
                
        )
    }
}

export default DepartmentView;

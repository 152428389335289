//import React from 'react'
import { Route, Link, Switch, Redirect, useParams } from 'react-router-dom';
import Topbar from  '../../components/common/Topbar'
import Footer from  '../../components/common/Footer'
import DoctorView from '../../components/doctor/DoctorView'

function DoctorViewScreen() {
    let { slug } = useParams();

    return (
        <div className="wrapper">
            <Topbar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                        <div className="page-content mb-5">
                            <div className="row mb-3">
                                <div className="col-md-6 col-xs-12">
                                    <h1 className="h3">Know Your Doctor</h1>
                                </div>
                                <div className="col-md-6 col-xs-12 text-right">
                                    <Link to="/doctors" className="btn btn-primary">All Doctors</Link> 
                                </div>
                            </div>
                            <DoctorView slug={slug}/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        
    )
}

export default DoctorViewScreen
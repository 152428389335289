//import React from 'react'
import { Route, Link, Switch, Redirect, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Topbar from  '../../components/common/Topbar'
import Footer from  '../../components/common/Footer'
import DepartmentView from '../../components/department/DepartmentView'

function DepartmentViewScreen() {
    let { slug } = useParams();
    return (
        <div className="wrapper">
            <Topbar />
            <Container fluid>
                <Row>
                    <Col md={12} xs={12}>
                        <div className="page-content mb-5">
                            <Row className="mb-3">
                                <div className="col-md-6 col-xs-12">
                                    <h1 className="h3">Know the Department</h1>
                                </div>
                                <div className="col-md-6 col-xs-12 text-right">
                                    <Link to="/departments" className="btn btn-primary">All Departments</Link> 
                                </div>
                            </Row>
                            <DepartmentView slug={slug}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
        
    )
}

export default DepartmentViewScreen
